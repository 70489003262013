import { Card, createStyles, makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useAsync } from 'react-use';

import styled from 'styled-components';
import closeCoachMovieListBtn from '../../assets/close_coach_movielist_btn.svg';
import openCoachMovieListBtn from '../../assets/open_coach_movielist_btn.svg';
import { StaffDisplay } from '../../store/TempoStore';
import Api from '../../utils/Api';
import {
    ClubFilter,
    getMovieComparer,
    getMoviePredicate, getRefineWithYardRange,
    Order, YardRange,
} from '../../utils/MovieSorting';
import { MovieDisplay } from '../../utils/Video';
import { colors } from '../const/Styles';
import CoachModalVideoList from './CoachModalVideoList';

const useStyles = makeStyles(() =>
    createStyles({
        paper: {
            width: '100%',
            height: '100%',
            backgroundColor: `${colors.white}`,
            display: 'flex',
            justifyContent: 'center',
        },
        upperBox: {
            display: 'flex',
            margin: 10,
        },
        selectBox: {
            marginLeft: 'auto',
        },
        select: {
            margin: 5,
        },
        wrapClubNameBox: {
            width: 664,
            backgroundColor: `${colors.white}`,
            display: 'flex',
            justifyContent: 'center',
            marginTop: 20,
        },
        clubNameBox: {
            width: 514,
            backgroundColor: `${colors.lighterGray}`,
            display: 'flex',
            flexWrap: 'wrap',
            paddingLeft: 15,
            paddingTop: 10,
        },
        imageBox: {
            display: 'flex',
            alignItems: 'center',
            width: 150,
            height: 149,
            backgroundColor: `${colors.lighterGray}`,
            position: 'relative',
        },
        nameBox: {
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: 40,
            backgroundColor: `${colors.black}`,
            opacity: 0.7,
            color: `${colors.white}`,
            textAlign: 'center',
        },
        nameSeimei: {
            fontSize: 13,
        },
        yomiNameSeimei: {
            fontSize: 11,
        },
        img: {
            height: '100%',
            width: '100%',
        },
        MovieBoxOpen: {
            width: 664,
            height: 20,
            backgroundColor: `${colors.lightGray3}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: `${colors.white}`,
        },
    }),
);

type CoarchMovieListBtntype = {
    openCoachMovieListFlg: boolean;
};
const CoarchMovieListBtn = styled.img<CoarchMovieListBtntype>`
    src: ${({ openCoachMovieListFlg }) =>
        openCoachMovieListFlg === true
            ? openCoachMovieListBtn
            : closeCoachMovieListBtn};
`;

interface Props {
    coach: StaffDisplay;
    club: ClubFilter;
    yard: YardRange;
    order: Order;
}

type colorFlgType = {
    colorflg: boolean;
};

type coachVisibleType = {
    coachMovieVisible: boolean;
};

const Wrapper = styled.div<coachVisibleType>`
    display: ${({ coachMovieVisible }) =>
        coachMovieVisible === true ? 'div' : 'none'};
`;

const ClubCount = styled.div<colorFlgType>`
    color: ${({ colorflg }) =>
        colorflg === true ? colors.blue : colors.black};
    margin-right: 18px;
    font-size: 20px;
    font-weight: bold;
`;

const ClubName = styled.div<colorFlgType>`
    background-color: ${({ colorflg }) =>
        colorflg === true ? colors.white : colors.lighterGray};
    width: 214px;
    height: 35px;
    // backgroundcolor: colors.white;
    border: 1px solid ${colors.lightGray3};
    marginright: 20px;
    marginbottom: 10px;
    display: flex;
    align-items: center;
    font-size: 13px;
    padding-left: 15px;
    justify-content: space-between;
`;

const CoachInfoModal: React.FC<Props> = ({ coach, club, yard, order }) => {
    const classes = useStyles();

    const [videos, setVideos] = useState<MovieDisplay[]>([]);

    const [openCoachMovieListFlg, setOpenCoachMovieListFlg] = useState(false);
    const handleCoachMovieList = useCallback(() => {
        setOpenCoachMovieListFlg(!openCoachMovieListFlg);
    }, [openCoachMovieListFlg]);

    const imgName =
        openCoachMovieListFlg === true
            ? openCoachMovieListBtn
            : closeCoachMovieListBtn;

    const clubTypeCount = {
        driver: 0,
        fairwayWood: 0,
        putter: 0,
        wedge: 0,
        iron: 0,
        utility: 0,
    };

    const visibleVideos = useMemo(() => {
        return [
            ...videos
                .filter(getMoviePredicate(club))
                .filter(getRefineWithYardRange(yard))
                .sort(getMovieComparer(order)),
        ];
    }, [club, yard, order, videos]);

    const displayVideos = useCallback(async () => {
        const fetchedVideos = await Api.fetchStaffMovies(coach.id);
        const fetchedVideoList = await Promise.all(
            fetchedVideos.map(async (entity) => {
                const movie = new MovieDisplay(entity);
                // eslint-disable-next-line @typescript-eslint/await-thenable
                await movie.fetchSrc();
                return movie;
            }),
        );
        setVideos(fetchedVideoList.filter((movie) => movie.fetchable));
    }, [coach.id]);

    useAsync(async () => {
        await displayVideos();
    });

    videos.map((video) => {
        if (video.club.toString() === 'ドライバー') {
            clubTypeCount.driver++;
        } else if (video.club.toString() === 'フェアウェイウッド') {
            clubTypeCount.fairwayWood++;
        } else if (video.club.toString() === 'ユーティリティ') {
            clubTypeCount.utility++;
        } else if (video.club.toString() === 'アイアン') {
            clubTypeCount.iron++;
        } else if (video.club.toString() === 'ウェッジ') {
            clubTypeCount.wedge++;
        } else if (video.club.toString() === 'パター') {
            clubTypeCount.putter++;
        }
    });

    let coarchVisible = true;
    if (club === '(クラブ選択)') {
        coarchVisible = true;
    } else if (videos.length !== 0) {
        coarchVisible = true;
    } else {
        coarchVisible = false;
    }

    return (
        <Card className={classes.paper}>
            <Wrapper coachMovieVisible={coarchVisible}>
                <div className={classes.wrapClubNameBox}>
                    <div className={classes.imageBox}>
                        <img className={classes.img} src={coach.s3Path} />
                        <div className={classes.nameBox}>
                            <div className={classes.nameSeimei}>
                                {coach.name_sei}
                                {coach.name_mei}
                            </div>
                            <div className={classes.yomiNameSeimei}>
                                {coach.name_sei_kana}
                                {coach.name_mei_kana}
                            </div>
                        </div>
                    </div>
                    <div className={classes.clubNameBox}>
                        <ClubName
                            colorflg={clubTypeCount.driver >= 1 ? true : false}
                        >
                            ドライバー
                            <ClubCount
                                colorflg={
                                    clubTypeCount.driver >= 1 ? true : false
                                }
                            >
                                {clubTypeCount.driver}
                            </ClubCount>
                        </ClubName>
                        <ClubName
                            colorflg={
                                clubTypeCount.fairwayWood >= 1 ? true : false
                            }
                        >
                            フェアウェイウッド
                            <ClubCount
                                colorflg={
                                    clubTypeCount.fairwayWood >= 1
                                        ? true
                                        : false
                                }
                            >
                                {clubTypeCount.fairwayWood}
                            </ClubCount>
                        </ClubName>
                        <ClubName
                            colorflg={clubTypeCount.utility >= 1 ? true : false}
                        >
                            ユーティリティ
                            <ClubCount
                                colorflg={
                                    clubTypeCount.utility >= 1 ? true : false
                                }
                            >
                                {clubTypeCount.utility}
                            </ClubCount>
                        </ClubName>
                        <ClubName
                            colorflg={clubTypeCount.iron >= 1 ? true : false}
                        >
                            アイアン
                            <ClubCount
                                colorflg={
                                    clubTypeCount.iron >= 1 ? true : false
                                }
                            >
                                {clubTypeCount.iron}
                            </ClubCount>
                        </ClubName>
                        <ClubName
                            colorflg={clubTypeCount.wedge >= 1 ? true : false}
                        >
                            ウェッジ
                            <ClubCount
                                colorflg={
                                    clubTypeCount.wedge >= 1 ? true : false
                                }
                            >
                                {clubTypeCount.wedge}
                            </ClubCount>
                        </ClubName>
                        <ClubName
                            colorflg={clubTypeCount.putter >= 1 ? true : false}
                        >
                            パター
                            <ClubCount
                                colorflg={
                                    clubTypeCount.putter >= 1 ? true : false
                                }
                            >
                                {clubTypeCount.putter}
                            </ClubCount>
                        </ClubName>
                    </div>
                </div>
                <div
                    className={classes.MovieBoxOpen}
                    onClick={handleCoachMovieList}
                >
                    <CoarchMovieListBtn
                        src={imgName}
                        openCoachMovieListFlg={openCoachMovieListFlg}
                        alt=''
                    />
                </div>
                <CoachModalVideoList
                    videos={visibleVideos}
                    selectedVideo={null}
                    isOpen={openCoachMovieListFlg}
                    coach={coach}
                />
            </Wrapper>
        </Card>
    );
};

export default CoachInfoModal;

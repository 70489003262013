import React, {createContext, useEffect, useState} from 'react';
import {
    ClubFilter,
    clubFilterUnset,
    ORDER_VALUE_CREATED_AT_DESC,
    OrderValueType,
    YARD_RANGE_NONE,
    YardRange,
    YardRangeValueType,
} from '../utils/MovieSorting';

export const MOVIE_TYPE_CUSTOMER = 0;
export const MOVIE_TYPE_COACH = 1;
export const MOVIE_TYPE_FAVORITED = 2;

export type MovieType =
    | typeof MOVIE_TYPE_CUSTOMER
    | typeof MOVIE_TYPE_COACH
    | typeof MOVIE_TYPE_FAVORITED;

type ContextType = {
    setMovieType: (value: MovieType) => void;
    movieType: MovieType;
    setSortOrder: (value: OrderValueType) => void;
    SortOrder: OrderValueType;
    setYardRange: (value: YardRangeValueType) => void;
    YardRanges: YardRangeValueType;
    setSortClub: (value: ClubFilter) => void;
    SortClub: ClubFilter;
};

const DefaultState: ContextType = {
    setMovieType: () => undefined,
    movieType: MOVIE_TYPE_CUSTOMER,
    setSortOrder: () => undefined,
    SortOrder: ORDER_VALUE_CREATED_AT_DESC,
    setYardRange: () => undefined,
    YardRanges: YARD_RANGE_NONE,
    setSortClub: () => undefined,
    SortClub: clubFilterUnset,
};

export const VideoTypeTabContext = createContext<ContextType>(DefaultState);

export const VideoTypeTabContextProvider: React.FC = ({ children }) => {
    const [movieType, setMovieType] = useState<MovieType>(MOVIE_TYPE_CUSTOMER);
    const [SortOrder, setSortOrder] =
        useState<OrderValueType>(ORDER_VALUE_CREATED_AT_DESC);
    const [YardRanges, setYardRange] = useState<YardRange>(YARD_RANGE_NONE);
    const [SortClub, setSortClub] = useState<ClubFilter>(clubFilterUnset);

    return (
        <VideoTypeTabContext.Provider
            value={{
                movieType,
                setMovieType,
                SortOrder,
                setSortOrder,
                YardRanges,
                setYardRange,
                SortClub,
                setSortClub,
            }}
        >
            {children}
        </VideoTypeTabContext.Provider>
    );
};

import { Card, Container, GridList } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import styled from 'styled-components';

type WrapperProps = {
    isVisible: boolean;
};

const Wrapper = styled.div<WrapperProps>`
    display: ${({ isVisible }) => (isVisible === true ? 'flex' : 'none')};
    flex-direction: row;
    box-sizing: border-box;
    flex-grow: 2;
    overflow: auto;
`;

const useStyles = makeStyles(() =>
    createStyles({
        mainCard: {
            width: '100%',
            boxShadow: 'none',
            overflow: 'auto',
        },
        videoListContainer: {
            paddingBottom: 10,
            background: '#fff',
        },
        title: {
            width: 40,
            height: 40,
            color: 'white',
        },
        gridListTile: {
            // GridListTileコンポーネントのdefaultのheight: 184pxとwidth: 20%を上書き
            height: '150px !important',
            width: '19% !important',
            marginRight: 5,
        },
    }),
);

type Props = {
    isVisible?: boolean;
};

const VideoGridLayout: React.FC<Props> = ({ isVisible = false, children }) => {
    const classes = useStyles();
    return (
        <Wrapper isVisible={isVisible}>
            <Card className={classes.mainCard}>
                <Container className={classes.videoListContainer}>
                    <GridList cols={5} spacing={5}>
                        {children}
                    </GridList>
                </Container>
            </Card>
        </Wrapper>
    );
};

export default VideoGridLayout;

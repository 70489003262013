import { Box, MenuItem, Select } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { MerchantTabContext } from '../../contexts/MerchantTabContext';
import TempoStore from '../../store/TempoStore';
import CoachInfoModal from '../coach/CoachInfoModal';
import { COACH_SWING_MOVIE } from '../const/MerchantProperties';

import {
    ClubFilter,
    clubFilters,
    clubFilterUnset,
    Order, ORDER_VALUE_CREATED_AT_DESC,
    orderMenu, YARD_RANGE_NONE, YardRange, yardRanges,
} from '../../utils/MovieSorting';

type ContainerProps = {
    visible: boolean;
};

const Container = styled.section<ContainerProps>`
    display: ${({ visible }) => (visible === true ? 'block' : 'none')};
    flexGrow: 1,
    padding: 30,
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridList: {
            width: '100%',
            height: 'auto',
            overflowY: 'visible',
        },
        gridListTile: {
            width: '50%',
            height: 'auto',
        },
        tile: {
            width: '80%',
            margin: 'auto',
            boxShadow: '7px 7px 7px grey',
            border: 'solid 1px grey',
        },
        TitlePaper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        ItemPaper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.primary,
            height: 400,
        },
        wrapbox: {
            padding: 20,
        },
        img: {
            height: '100%',
            width: '100%',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        selectBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        select: {
            marginRight: 40,
            marginLeft: 10,
        },
    }),
);

const CoachVideoList: React.FC = () => {
    const classes = useStyles();

    const { selected } = useContext(MerchantTabContext);
    const visible = useMemo(() => {
        return COACH_SWING_MOVIE === selected;
    }, [selected]);

    const [club, setClub] = useState<ClubFilter>(clubFilterUnset);
    const [yardRange, setYardRange] = useState<YardRange>(YARD_RANGE_NONE);
    const [order, setOrder] = useState<Order>(ORDER_VALUE_CREATED_AT_DESC);

    return (
        <Container visible={visible}>
            <Box className={classes.wrapbox}>
                <Box className={classes.selectBox}>
                    クラブ選択
                    <Select
                        className={classes.select}
                        value={club}
                        variant='outlined'
                        onChange={(e) => {
                            setClub(e.target.value as ClubFilter);
                        }}
                    >
                        {clubFilters.map((clubFilter) => (
                            <MenuItem key={clubFilter} value={clubFilter}>
                                {clubFilter}
                            </MenuItem>
                        ))}
                    </Select>
                    飛距離選択
                    <Select
                        className={classes.select}
                        value={yardRange}
                        variant='outlined'
                        onChange={(e) => {
                            setYardRange(e.target.value as YardRange);
                        }}
                    >
                        {yardRanges.map((yardRange) => {
                            return (
                                <MenuItem key={yardRange.value} value={yardRange.value}>
                                    {yardRange.title}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    日付
                    <Select
                        className={classes.select}
                        value={order}
                        variant='outlined'
                        onChange={(e) => {
                            setOrder(e.target.value as Order);
                        }}
                    >
                        {orderMenu.map((menu) => {
                            return (
                                <MenuItem key={menu.value} value={menu.value}>
                                    {menu.title}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Box>
                <div className={classes.gridList}>
                    {TempoStore.allCoaches.map((coach, index) => {
                        return (
                            <CoachInfoModal
                                coach={coach}
                                key={index}
                                club={club}
                                yard={yardRange}
                                order={order}
                            />
                        );
                    })}
                </div>
            </Box>
        </Container>
    );
};
export default observer(CoachVideoList);

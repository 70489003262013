import React from 'react';
import styled from 'styled-components';
import { Toolbar } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import { colors, iconSize } from '../../const/Styles';
import ReloadIcon from '../../../assets/reload.png';

type Props = {
    icon?: string;
    title: string;
    handleClick?: () => void;
    isDisabledReloadButton?: boolean;
};

const StyledToolbar = styled(Toolbar)`
    min-height: 30px;
    background-color: ${colors.blue};
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: ${colors.white};
    font-size: 1.5rem;
    svg {
        width: auto;
        height: ${iconSize.header};
    }

    .Icon {
        display: flex;
        div {
            display: flex;
        }
    }
`;

const ReloadButton = styled.button`
    position: absolute;
    width: 70px;
    height: 30px;
    left: 1%;
    outline: none;
    border: none;
    border-radius: 4px;
    color: ${colors.black};
    background-color: ${colors.footerIconSelected};
    font-size: 16px;
    font-weight: bold;
    text-align: end;
    &:before {
        content: url(${ReloadIcon});
        position: absolute;
        left: 0%;
    }
    &:disabled {
        background-color: ${colors.gray};
    }
`;

const DisabledReloadButton = styled.button`
    position: absolute;
    width: 70px;
    height: 30px;
    left: 1%;
    outline: none;
    border: none;
    border-radius: 4px;
    color: ${colors.gray};
    background-color: ${colors.footerIconUnSelected};
    font-size: 16px;
    font-weight: bold;
    text-align: end;
    &:before {
        content: url(${ReloadIcon});
        position: absolute;
        left: 0%;
    }
`;

const Header: React.FC<Props> = ({ icon, title, handleClick, isDisabledReloadButton }) => {
    const reloadButton = () => {
        if (!handleClick) {
            return null;
        }

        if (isDisabledReloadButton) {
            return (
                <DisabledReloadButton>
                    更新
                </DisabledReloadButton>
            );
        }

        return (
            <ReloadButton onClick={handleClick}>
                更新
            </ReloadButton>
        );
    };

    return (
        <StyledToolbar>
            <Content>
                { reloadButton() }
                {icon && <ReactSVG src={icon} className='Icon' />}
                {title}
            </Content>
        </StyledToolbar>
    );
};

export default Header;

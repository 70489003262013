import { makeAutoObservable, runInAction } from 'mobx';
import { CustomerEntity } from '../components/@types/mysqlEntities';

import Api, { LessonEntity } from '../utils/Api';
import { LessonDisplay } from '../utils/Lesson';
import { isNotUndefined } from '../utils/utilityFuncs';

export interface CustomerDisplay extends CustomerEntity {
    s3Path: string;
    is_deleted: boolean
}

export const dominantHandMap = {
    left_handed: '左利き',
    right_handed: '右利き',
    unknown: '未設定',
};
export const personalityMap = {
    action: '行動型',
    sense: '感覚型',
    observe: '観察型',
    think: '思案型',
    unknown: '未設定',
};

export type Rows =
    | 'あ行'
    | 'か行'
    | 'さ行'
    | 'た行'
    | 'な行'
    | 'は行'
    | 'ま行'
    | 'や行'
    | 'ら行'
    | 'わ行';
export type CustomersPerRow = { [row in Rows]: CustomerDisplay[] };

// noinspection NonAsciiCharacters
class LessonStore {
    constructor() {
        makeAutoObservable<LessonStore>(this, {}, { autoBind: true });
    }

    isCustomerLoaded = false;
    isInclusionDeletedCustomer = false;
    allCustomers: CustomerDisplay[] = [];

    async fetchAllCustomers(tempoId: string, showDeleted = false) {
        const fetchedCustomers = await Api.getCustomers(tempoId, showDeleted);
        const allCustomers = fetchedCustomers.map((customer: CustomerEntity): CustomerDisplay => {
            return {
                ...customer,
                s3Path: customer.signed_url ?? `${import.meta.env.BASE_URL}sample_account.png`,
                is_deleted: typeof customer.deleted_at === 'string',
            };
        });
        runInAction(() => {
            this.allCustomers = allCustomers.sort((a, b) =>
                a.name_sei_kana.localeCompare(b.name_sei_kana, 'ja'),
            );
            this.isCustomerLoaded = true;
        });
    }

    rows: Rows[] = [
        'あ行',
        'か行',
        'さ行',
        'た行',
        'な行',
        'は行',
        'ま行',
        'や行',
        'ら行',
        'わ行',
    ];
    selectedCustomersId: number[] = [];

    viewedCustomer: CustomerDisplay = this.selectedCustomers[0];

    setViewedCustomer(customer?: CustomerDisplay) {
        this.viewedCustomer = customer || this.selectedCustomers[0];
    }

    get ALL行(): CustomerDisplay[] {
        return [...this.allCustomers].sort((a, b) =>
            a.name_sei_kana[0].localeCompare(b.name_sei_kana[0], 'ja'),
        )
    }

    get あ行(): CustomerDisplay[] {
        return this.allCustomers
            .filter((customer) => {
                return /[ぁ-おゔ]/.test(customer.name_sei_kana[0]);
            })
            .sort((a, b) =>
                a.name_sei_kana[0].localeCompare(b.name_sei_kana[0], 'ja'),
            );
    }

    get か行(): CustomerDisplay[] {
        return this.allCustomers
            .filter((customer) => {
                return /[か-ごゕゖ]/.test(customer.name_sei_kana[0]);
            })
            .sort((a, b) =>
                a.name_sei_kana[0].localeCompare(b.name_sei_kana[0], 'ja'),
            );
    }

    get さ行(): CustomerDisplay[] {
        return this.allCustomers
            .filter((customer) => {
                return /[さ-ぞ]/.test(customer.name_sei_kana[0]);
            })
            .sort((a, b) =>
                a.name_sei_kana[0].localeCompare(b.name_sei_kana[0], 'ja'),
            );
    }

    get た行(): CustomerDisplay[] {
        return this.allCustomers
            .filter((customer) => {
                return /[た-ど]/.test(customer.name_sei_kana[0]);
            })
            .sort((a, b) =>
                a.name_sei_kana[0].localeCompare(b.name_sei_kana[0], 'ja'),
            );
    }

    get な行(): CustomerDisplay[] {
        return this.allCustomers
            .filter((customer) => {
                return /[な-の]/.test(customer.name_sei_kana[0]);
            })
            .sort((a, b) =>
                a.name_sei_kana[0].localeCompare(b.name_sei_kana[0], 'ja'),
            );
    }

    get は行(): CustomerDisplay[] {
        return this.allCustomers
            .filter((customer) => {
                return /[は-ぽ]/.test(customer.name_sei_kana[0]);
            })
            .sort((a, b) =>
                a.name_sei_kana[0].localeCompare(b.name_sei_kana[0], 'ja'),
            );
    }

    get ま行(): CustomerDisplay[] {
        return this.allCustomers
            .filter((customer) => {
                return /[ま-も]/.test(customer.name_sei_kana[0]);
            })
            .sort((a, b) =>
                a.name_sei_kana[0].localeCompare(b.name_sei_kana[0], 'ja'),
            );
    }

    get や行(): CustomerDisplay[] {
        return this.allCustomers
            .filter((customer) => {
                return /[ゃ-よ]/.test(customer.name_sei_kana[0]);
            })
            .sort((a, b) =>
                a.name_sei_kana[0].localeCompare(b.name_sei_kana[0], 'ja'),
            );
    }

    get ら行(): CustomerDisplay[] {
        return this.allCustomers
            .filter((customer) => {
                return /[ら-ろ]/.test(customer.name_sei_kana[0]);
            })
            .sort((a, b) =>
                a.name_sei_kana[0].localeCompare(b.name_sei_kana[0], 'ja'),
            );
    }

    get わ行(): CustomerDisplay[] {
        return this.allCustomers
            .filter((customer) => {
                return /[ゎ-ん]/.test(customer.name_sei_kana[0]);
            })
            .sort((a, b) =>
                a.name_sei_kana[0].localeCompare(b.name_sei_kana[0], 'ja'),
            );
    }

    customersPerRow(row: string): CustomerDisplay[] {
        switch (row) {
            case 'ALL':
                return this.ALL行;
            case 'あ行':
                return this.あ行;
            case 'か行':
                return this.か行;
            case 'さ行':
                return this.さ行;
            case 'た行':
                return this.た行;
            case 'な行':
                return this.な行;
            case 'は行':
                return this.は行;
            case 'ま行':
                return this.ま行;
            case 'や行':
                return this.や行;
            case 'ら行':
                return this.ら行;
            case 'わ行':
                return this.わ行;
            default:
                return this.ALL行;
        }
    }

    resolveCustomerName(customerId: number): string[] | undefined {
        const customer = this.allCustomers.find((p) => p.id === customerId);
        if (customer) {
            return [customer.name_sei, customer.name_mei];
        }
    }

    get selectedCustomers(): CustomerDisplay[] {
        return this.selectedCustomersId
            .map((id) =>
                this.allCustomers.find((customer) => customer.id == id),
            )
            .filter(isNotUndefined);
    }

    lessonRecordPerCustomer: {
        customerId: string;
        records: string[];
        comment: string;
    }[] = [];
    getLessonRecord(customerId: string): string[] | undefined {
        const target = this.lessonRecordPerCustomer.find((object) => {
            return object.customerId == customerId;
        });
        return target?.records;
    }
    setLessonRecord(customerId: string, records: string[]) {
        const target = this.lessonRecordPerCustomer.find((object) => {
            return object.customerId == customerId;
        });
        if (target) {
            const index = this.lessonRecordPerCustomer.findIndex(
                (p) => p.customerId === customerId,
            );
            this.lessonRecordPerCustomer.splice(index, 1, {
                ...target,
                records,
            });
        } else {
            this.lessonRecordPerCustomer.push({
                customerId: customerId,
                records: records,
                comment: '',
            });
        }
    }
    getLessonComment(customerId: string): string {
        const target = this.lessonRecordPerCustomer.find((object) => {
            return object.customerId == customerId;
        });
        return target ? target.comment : '';
    }
    setLessonComment = (customerId: string, comment: string) => {
        const target = this.lessonRecordPerCustomer.find((object) => {
            return object.customerId == customerId;
        });
        if (target) {
            target.comment = comment;
        } else {
            this.lessonRecordPerCustomer.push({
                customerId,
                comment,
                records: Array(10).fill('0') as string[],
            });
        }
    };

    pastLessons = new Map<number, LessonEntity[]>();
    async fetchPastLessons(customerId: number) {
        const fetchedLessons = await Api.fetchCustomerPastLessons(customerId);
        const fetchedLessonDisplays = fetchedLessons.map(
            (entity) => new LessonDisplay(entity),
        );
        runInAction(() => {
            this.pastLessons.set(customerId, fetchedLessons);
        });
        return fetchedLessonDisplays;
    }

    async setPastLessonComment(
        customerId: string,
        lessonAchievementId: string,
        comment: string,
    ) {
        const currentPastLessons = this.pastLessons.get(Number(customerId));
        const currentPastLesson =
            currentPastLessons?.filter((pastLessons) => {
                return `${pastLessons.id}` === lessonAchievementId;
            }) ?? undefined;

        if (!currentPastLessons || currentPastLesson === undefined) {
            return;
        }

        await Api.editLessonComment(customerId, lessonAchievementId, comment);

        runInAction(() => {
            this.pastLessons = this.pastLessons.set(
                Number(customerId),
                currentPastLessons.map((entity) => {
                    if (`${entity.id}` === lessonAchievementId) {
                        entity.comment = comment;
                    }
                    return entity;
                }),
            );
            const newMap = new Map<number, LessonEntity[]>();
            this.pastLessons = newMap.set(
                Number(customerId),
                currentPastLessons.map((entity) => {
                    if (`${entity.id}` === lessonAchievementId) {
                        entity.comment = comment;
                    }
                    return entity;
                }),
            );
        });
    }

    /**
     * レッスン実績削除
     * @param customerId
     * @param lessonAchievementId
     */
    async deletePastLesson(
        customerId: string,
        lessonAchievementId: string
    ): Promise<void> {
        const target = await Api.deleteAchievedLesson(customerId, lessonAchievementId);
        runInAction(() => {
            const numericCustomerId = parseInt(target.customer_id);
            const customerPastLessons = this.pastLessons.get(numericCustomerId)

            if (!customerPastLessons) {
                return;
            }
            const id = parseInt(lessonAchievementId);

            this.pastLessons.set(numericCustomerId, customerPastLessons.filter(pastLesson => id !== pastLesson.id));
        })
    }

    isSelected(customerId: number): boolean {
        return this.selectedCustomersId.includes(customerId);
    }

    selectCustomer(customerId: number): void {
        if (this.isSelected(customerId)) {
            this.unselectCustomer(customerId);
        } else {
            this.selectedCustomersId.push(customerId);
        }
        this.viewedCustomer = this.selectedCustomers[0];
    }

    unselectCustomer(customerId: number): void {
        const index = this.selectedCustomersId.indexOf(customerId);
        if (index > -1) {
            this.selectedCustomersId.splice(index, 1);
        }

        // レッスンノートで選択中の顧客が指定されていたら、表示情報はリストの最初にある顧客を設定する
        if (this.selectedCustomers.length > 0 && this.viewedCustomer.id === customerId) {
            this.setViewedCustomer(this.selectedCustomers[0]);
        }
    }

    initTodaysLesson(customerId: string): void {
        this.setLessonComment(customerId, '');
    }
}

export default new LessonStore();

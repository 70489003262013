import { SwingClubs } from '../components/const/SwingClub';
import { MovieEntity } from './Api';
const collator = new Intl.Collator(undefined, { numeric: true });
const compare = (x: MovieEntity, y: MovieEntity, k: keyof MovieEntity) =>
    collator.compare(<string>x[k], <string>y[k]);

export const clubFilterUnset = '(クラブ選択)';
export const clubFilters = [clubFilterUnset, ...SwingClubs] as const;

export type ClubFilter = typeof clubFilters[number];

export function getMoviePredicate(
    club: ClubFilter,
): (x: MovieEntity) => boolean {
    if (club === clubFilterUnset) return () => true;
    return (video) => video.club === club;
}

export const ORDER_VALUE_CREATED_AT_DESC = 1;
export const ORDER_VALUE_CREATED_AT_ASC = 2;
export type OrderValueType =
    | typeof ORDER_VALUE_CREATED_AT_DESC
    | typeof ORDER_VALUE_CREATED_AT_ASC;

export const orderMenu = [
    { title: '新しい順', value: ORDER_VALUE_CREATED_AT_DESC },
    { title: '古い順', value: ORDER_VALUE_CREATED_AT_ASC },
] as const;

export type Order = typeof orderMenu[number]['value'];

export const YARD_RANGE_NONE = 0;
export const YARD_RANGE_FROM_MIN_TO_30 = 1;
export const YARD_RANGE_FROM_31_TO_60 = 2;
export const YARD_RANGE_FROM_61_TO_90 = 3;
export const YARD_RANGE_FROM_91_TO_120 = 4;
export const YARD_RANGE_FROM_121_TO_150 = 5;
export const YARD_RANGE_FROM_151_TO_180 = 6;
export const YARD_RANGE_FROM_181_TO_210 = 7;
export const YARD_RANGE_FROM_211_TO_240 = 8;
export const YARD_RANGE_FROM_241_TO_270 = 9;
export const YARD_RANGE_FROM_271_TO_MAX = 10;

export type YardRangeValueType =
    | typeof YARD_RANGE_NONE
    | typeof YARD_RANGE_FROM_MIN_TO_30
    | typeof YARD_RANGE_FROM_31_TO_60
    | typeof YARD_RANGE_FROM_61_TO_90
    | typeof YARD_RANGE_FROM_91_TO_120
    | typeof YARD_RANGE_FROM_121_TO_150
    | typeof YARD_RANGE_FROM_151_TO_180
    | typeof YARD_RANGE_FROM_181_TO_210
    | typeof YARD_RANGE_FROM_211_TO_240
    | typeof YARD_RANGE_FROM_241_TO_270
    | typeof YARD_RANGE_FROM_271_TO_MAX;

export const yardRanges = [
    { title: '(飛距離選択)', value: YARD_RANGE_NONE },
    { title: '～30', value: YARD_RANGE_FROM_MIN_TO_30 },
    { title: '31～60', value: YARD_RANGE_FROM_31_TO_60 },
    { title: '61～90', value: YARD_RANGE_FROM_61_TO_90 },
    { title: '91～120', value: YARD_RANGE_FROM_91_TO_120 },
    { title: '121～150', value: YARD_RANGE_FROM_121_TO_150 },
    { title: '151～180', value: YARD_RANGE_FROM_151_TO_180 },
    { title: '181～210', value: YARD_RANGE_FROM_181_TO_210 },
    { title: '211～240', value: YARD_RANGE_FROM_211_TO_240 },
    { title: '241～270', value: YARD_RANGE_FROM_241_TO_270 },
    { title: '271～', value: YARD_RANGE_FROM_271_TO_MAX },
] as const;

export type YardRange = typeof yardRanges[number]['value'];

// TODO: 検索系は全てBEに任す
export function getMovieComparer(
    order: Order,
): (x: MovieEntity, y: MovieEntity) => number {
    if (order === ORDER_VALUE_CREATED_AT_DESC) {
        return (x: MovieEntity, y: MovieEntity) =>
            compare(y, x, 'created_at');
    }

    return (x: MovieEntity, y: MovieEntity) =>
        compare(x, y, 'created_at');
}

export function getRefineWithYardRange(
    range: YardRange,
): (x: MovieEntity) => boolean {
    return (video) => {
        switch (Number(range.toString())) {
            case YARD_RANGE_FROM_MIN_TO_30:
                console.log('YARD_RANGE_FROM_MIN_TO_30', range, video)
                return Number(video.yards) <= 30;
            case YARD_RANGE_FROM_31_TO_60:
                return Number(video.yards) >= 31 && Number(video.yards) <= 60;
            case YARD_RANGE_FROM_61_TO_90:
                return Number(video.yards) >= 61 && Number(video.yards) <= 90;
            case YARD_RANGE_FROM_91_TO_120:
                return Number(video.yards) >= 91 && Number(video.yards) <= 120;
            case YARD_RANGE_FROM_121_TO_150:
                return Number(video.yards) >= 121 && Number(video.yards) <= 150;
            case YARD_RANGE_FROM_151_TO_180:
                return Number(video.yards) >= 151 && Number(video.yards) <= 180;
            case YARD_RANGE_FROM_181_TO_210:
                return Number(video.yards) >= 181 && Number(video.yards) <= 210;
            case YARD_RANGE_FROM_211_TO_240:
                return Number(video.yards) >= 211 && Number(video.yards) <= 240;
            case YARD_RANGE_FROM_241_TO_270:
                return Number(video.yards) >= 241 && Number(video.yards) <= 270;
            case YARD_RANGE_FROM_271_TO_MAX:
                return Number(video.yards) >= 271;
            default:
                return true;
        }
    }
}

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Webcam from 'react-webcam';
import HeaderIcon from '../../assets/merchant.svg';
import { useStore } from '../../contexts/StoreContext';
import { SavedToolImageMap } from '../../store/RecordingStore';
import { StaffDisplay } from '../../store/TempoStore';
import Page from '../base/Page';
import { colors } from '../const/Styles';
import RecordVideoThumbnail from '../lesson/surface/RecordVideoThumbnail';
import UploadMovieModal from './input/UploadMovieModal';
import MovieEditFooter from './navigation/MovieEditFooter';
import PlayerHeaderNavigation from './navigation/PlayerHeaderNavigation';
import RecordControlButton from '../lesson/display/RecordControlButton';
import LocalMovieSelectButton from '../base/input/LocalMovieSelectButton';

const ContentContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    background-color: ${colors.white};
`;

const VideoContainer = styled.div`
    position: relative;
    width: fit-content;
    height: 450px;
    border: solid 1px ${colors.black};
    margin: 50px auto;
`;

const StyledControlButton = styled(RecordControlButton)`
    position: absolute;
    bottom: 5px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    z-index: 1;
`;

const SlideRecordVideo = styled.div`
    height: 145px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: ${colors.lightGray3};
    overflow-x: scroll;
`;

type GuideLineProps = {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
};

const HorizontalGuideLine = styled.p<GuideLineProps>`
    width: 100%;
    height: 1px;
    background-color: ${colors.red};
    opacity: 0.7;
    position: absolute;
    ${({ top, bottom, left, right }) => {
        const position = {};
        if (typeof left === 'number') {
            Object.assign(position, { left: `${left}px` });
        }
        if (typeof right === 'number') {
            Object.assign(position, { right: `${right}px` });
        }
        if (typeof top === 'number') {
            Object.assign(position, { top: `${top}px` });
        }
        if (typeof bottom === 'number') {
            Object.assign(position, { bottom: `${bottom}px` });
        }
        return position;
    }}
`;

const VerticalGuideLine = styled(HorizontalGuideLine)`
    width: 1px;
    height: 100%;
`;

const StyledLocalMovieSelectButton = styled(LocalMovieSelectButton)`
    position: absolute;
    right: 100%;
`;

const RecordMoviePage: React.FC = () => {
    const location = useLocation<StaffDisplay>();

    const webcamRef = useRef<Webcam>(null);
    const videoConstraints: MediaTrackConstraints = {
        facingMode: { exact: 'environment' },
    };

    const { recordingStore, lessonStore } = useStore();

    useEffect(() => {
        lessonStore.setViewedCustomer({
            ...location.state,
            password: '',
            date_of_birth: location.state.date_of_birth || '',
            profile_picture_filename: '',
            profile_picture_filepath: '',
            height: null,
            grip_right: null,
            grip_left: null,
            dominant_hand: 'left_handed',
            personality_1: 'action',
            personality_2: 'action',
            purpose_begin: '',
            target: '',
            lesson_master_id: 0,
            user_name: '',
            email: '',
            registered_at: '',
            signed_url: null,
            has_password: false,
            is_deleted: false,
        });
        return () => {
            lessonStore.setViewedCustomer();
        };
    }, [location.state, lessonStore]);

    const [isRecording, setIsRecording] = useState(false);
    const handleClickRecord = useCallback(() => {
        if (isRecording) {
            recordingStore.handleStopCaptureClick();
        } else {
            recordingStore.handleStartCaptureClick(webcamRef);
        }
        setIsRecording(!isRecording);
    }, [isRecording, setIsRecording, recordingStore]);

    const [saves, setSaves] = useState<SavedToolImageMap[]>([]);
    const [selected, setSelected] = useState<SavedToolImageMap>();
    const handleModalOpen = (data: SavedToolImageMap) => {
        setSelected(data);
    };

    const handleModalClose = () => {
        setSelected(undefined);
    };

    const handleAfterAdd = useCallback((current: SavedToolImageMap[]) => {
        setSaves(current.concat());
        setSelected(current[current.length - 1]);
    }, []);

    const handleRemove = useCallback(
        (data: SavedToolImageMap) => {
            setSaves(saves.filter((p) => p.id !== data.id).concat());
            recordingStore.removeVideo(data.id);
        },
        [recordingStore, saves],
    );

    useEffect(() => {
        recordingStore.afterAddHook = handleAfterAdd;
        return () => {
            recordingStore.afterAddHook = undefined;
        };
    }, [handleAfterAdd, recordingStore]);

    const history = useHistory();
    const handleGoBack = () => {
        history.goBack();
    };

    const handleChangeLocalMovie = (file: Blob) => {
        recordingStore.addLocalMovieToViewedCustomer(file);
    }

    return (
        <Page title='設定' headerIcon={HeaderIcon ?? ''}>
            <PlayerHeaderNavigation>コーチスイング撮影</PlayerHeaderNavigation>
            <ContentContainer>
                <SlideRecordVideo>
                    {saves.map((toolMap) => (
                        <RecordVideoThumbnail
                            key={toolMap.id}
                            data={toolMap}
                            onClick={handleModalOpen}
                        />
                    ))}
                </SlideRecordVideo>
                <VideoContainer>
                    <StyledControlButton
                        recording={isRecording}
                        onClick={handleClickRecord}
                    />
                    <StyledLocalMovieSelectButton onChange={handleChangeLocalMovie} />
                    <Webcam
                        ref={webcamRef}
                        width='auto'
                        height='100%'
                        audio={false}
                        videoConstraints={videoConstraints}
                    />
                    <HorizontalGuideLine top={30} left={0} />
                    <HorizontalGuideLine bottom={30} left={0} />
                    <VerticalGuideLine left={96} top={-15} />
                    <VerticalGuideLine right={96} top={-15} />
                </VideoContainer>
                <MovieEditFooter onClick={handleGoBack}>
                    コーチスイングに戻る
                </MovieEditFooter>
            </ContentContainer>
            <UploadMovieModal
                onClose={handleModalClose}
                onRemove={handleRemove}
                coach={location.state}
                video={selected}
            />
        </Page>
    );
};

export default RecordMoviePage;

import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useCallback, useContext } from 'react';
import styled from 'styled-components';
import {
    ClubFilter,
    clubFilters,
    Order,
    orderMenu, YardRange, yardRanges,
} from '../../../utils/MovieSorting';
import { colors } from '../../const/Styles';

import { VideoTypeTabContext } from '../../../contexts/VideoTypeTabContext';

const Container = styled.main`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px 0;
    width: 100%;
    font-size: 15px;
    color: ${colors.black};
    background-color: ${colors.white};
    box-sizing: border-box;
`;

const SelectContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SortBox = styled.select`
    background-color: ${colors.white};
    color: ${colors.black};
    padding: 5px;
    font-size: 15px;
`;

const SelectLabel = styled.div`
    background-color: ${colors.white};
    margin-right: 0.5rem;
`;

const SelectSortBox: React.FC = () => {
    const { SortClub, setSortClub, YardRanges, setYardRange, SortOrder, setSortOrder } =
        useContext(VideoTypeTabContext);

    const handleChangeClubType = useCallback(
        (evt: ChangeEvent<HTMLSelectElement>) => {
            setSortClub(evt.currentTarget.value as ClubFilter);
        },
        [setSortClub],
    );

    const handleChangeYardRanges = useCallback(
        (evt: ChangeEvent<HTMLSelectElement>) => {
            setYardRange(Number(evt.currentTarget.value) as YardRange);
        },
        [setYardRange],
    );

    const handleChangeOrder = useCallback(
        (evt: ChangeEvent<HTMLSelectElement>) => {
            setSortOrder(Number(evt.currentTarget.value) as Order);
        },
        [setSortOrder],
    );

    return (
        <Container>
            <SelectContainer>
                <SelectLabel>クラブ選択</SelectLabel>
                <SortBox value={SortClub} onChange={handleChangeClubType}>
                    {clubFilters.map((menu) => (
                        <option key={menu} value={menu}>
                            {menu}
                        </option>
                    ))}
                </SortBox>
            </SelectContainer>
            <SelectContainer>
                <SelectLabel>飛距離選択</SelectLabel>
                <SortBox value={YardRanges} onChange={handleChangeYardRanges}>
                    {yardRanges.map((yardRange) => (
                        <option key={yardRange.value} value={yardRange.value}>
                            {yardRange.title}
                        </option>
                    ))}
                </SortBox>
            </SelectContainer>
            <SelectContainer>
                <SelectLabel>日付</SelectLabel>
                <SortBox value={SortOrder} onChange={handleChangeOrder}>
                    {orderMenu.map((menu) => {
                        return (
                            <option key={menu.value} value={menu.value}>
                                {menu.title}
                            </option>
                        );
                    })}
                </SortBox>
            </SelectContainer>
        </Container>
    );
};

export default observer(SelectSortBox);

import { GridListTile } from '@material-ui/core';
import React, { useContext, useMemo, useState } from 'react';
import { useAsync } from 'react-use';
import styled from 'styled-components';

import {
    MOVIE_TYPE_CUSTOMER,
    VideoTypeTabContext,
} from '../../../contexts/VideoTypeTabContext';
import Api from '../../../utils/Api';
import {
    getMovieComparer,
    getMoviePredicate, getRefineWithYardRange,
} from '../../../utils/MovieSorting';
import { MovieDisplay } from '../../../utils/Video';
import MovieThumbnail from '../display/MovieThumbnail';
import VideoGridLayout from '../layout/VideoGridLayout';
import { useStore } from '../../../contexts/StoreContext';

const StyledGridListTile = styled(GridListTile)`
    height: 150px !important;
    width: 19% !important;
    margin-right: 5px;
`;

type Props = {
    customerId: number;
    onClick?: (video: MovieDisplay) => void;
};

const CustomerMovies: React.FC<Props> = ({ customerId, onClick }) => {
    const { movieType, SortClub, YardRanges, SortOrder } = useContext(VideoTypeTabContext);
    const { tempoStore } = useStore();
    const { favoriteMovies } = tempoStore;
    const [movies, setMovies] = useState<MovieDisplay[]>([]);
    // FIXME: 元々撮影済みの動画を表示しているが一過性のデータなので何も操作できない
    // 注意点はレッスンで選択された動画ではなく表示する意味が無いので一旦オミット
    useAsync(async () => {
        const results = await Api.fetchCustomerMovies(customerId);
        setMovies(
            results
                .map((result) => new MovieDisplay(result))
                // memo androidからアップロードした動画は値が足りないのでこのタイミングで埋め込む
                .filter((result) =>
                    result.movie_thumbnail_filename === ''
                        ? ((result.movie_thumbnail_filepath =
                              result.filename.replace('.mp4', '.png')),
                          (result.filepath = result.filename))
                        : [],
                )
                .filter((p) => p.fetchable),
        );
    }, [customerId, favoriteMovies.length]);

    const sortedMovies = useMemo(() => {
        return movies
            .filter(getMoviePredicate(SortClub))
            .filter(getRefineWithYardRange(YardRanges))
            .sort(getMovieComparer(SortOrder));
    }, [movies, SortClub, YardRanges, SortOrder]);

    return (
        <VideoGridLayout isVisible={movieType === MOVIE_TYPE_CUSTOMER}>
            {sortedMovies.map((video) => (
                <StyledGridListTile key={video.id}>
                    <MovieThumbnail video={video} onClick={onClick} />
                </StyledGridListTile>
            ))}
        </VideoGridLayout>
    );
};

export default CustomerMovies;
